var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"institution-pane"}},[_c('div',{staticClass:"dash-side-container dash-side-container-topNavbar"},[_c('ToolBar',{attrs:{"title":_vm.$route.meta.pageTitle},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"loading":_vm.pageLoading || _vm.isLoading,"small":"","color":"accent","icon":"","fab":""},on:{"click":function($event){return _vm.$store.dispatch('users/listAllPurchasedTickets')}}},on),[_c('i',{staticClass:"material-icons-outlined"},[_vm._v("cloud_done")])])]}}])},[_c('span',{staticClass:"ft font-weight-medium"},[_vm._v("Get Latest Changes")])])]},proxy:true}])})],1),_c('v-row',{staticClass:"container-view",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[(!_vm.internetState)?_c('div',{staticClass:"d-flex flex-row flex-wrap",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap pa-3"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1"},[(_vm.pageLoading)?_c('div',{staticClass:"d-flex flex-row flex-grow-1"},[_c('ProgressBar',{style:({ height: '10vh' }),attrs:{"size":40,"center":true}})],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-wrap flex-shrink-1"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 flex-shrink-1 flex-wrap"},[_c('div',{staticClass:"d-flex flex-row mx-1"},[_c('div',{staticClass:"d-flex flex-column flex-full flex-grow-1 flex-shrink-1"},[_c('v-tabs',{staticClass:"vx-shadow white pa-2 rounded-md d-flex flex-column flex-grow-1",staticStyle:{"flex":"0 !important"},attrs:{"left":"","icons-and-text":""},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tabs-slider'),_vm._l((_vm.tabs),function(ref,index){
var text = ref.text;
var image = ref.image;
return _c('v-tab',{key:index},[_c('span',{staticClass:"ft font-weight-medium text-capitalize text-sm"},[_vm._v(_vm._s(text))]),_c('img',{staticClass:"mb-2",staticStyle:{"width":"25px"},attrs:{"src":image}})])})],2),_c('v-tabs-items',{staticStyle:{"background-color":"transparent"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('v-tab-item',[_c('v-chip-group',{model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},_vm._l((_vm.chipGroups),function(tag,index){return _c('v-chip',{key:"chp-" + index,staticClass:"ft font-weight-medium text-sm vx-shadow",attrs:{"outlined":"","filter":"","active-class":"primary--text","small":"","value":tag}},[_vm._v(" "+_vm._s(tag)+" ")])}),1),(_vm.purchasedTickets.length > 0)?_c('div',{staticClass:"d-flex flex-row flex-grow-1 flex-wrap"},_vm._l((_vm.purchasedTickets),function(ref,index){
var ticket = ref.ticket;
var event = ref.event;
var amountPaid = ref.amountPaid;
return _c('div',{directives:[{name:"ripple",rawName:"v-ripple"}],key:'purchased-tickets' + index,staticClass:"d-flex flex-row cursor-pointer white vx-shadow rounded-md ticket ticket-title ma-1",on:{"click":function($event){$event.stopPropagation();return _vm.openTicketDetails({
                                    ticket: ticket,
                                    event: event,
                                    amountPaid: amountPaid,
                                  })}}},[_c('div',{staticClass:"d-flex flex-column flex-grow-1 pa-2"},[_c('div',{staticClass:"d-flex flex-column flex-grow-1"},[_c('h3',{staticClass:"ft font-weight-medium text-md text-uppercase text-shadow"},[_vm._v(" "+_vm._s(ticket.name)+" ")]),_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Start Date: "+_vm._s(_vm._f("timestamp")(event.noticesValidFor.startDateTime,'Do, MMM YYYY HH:MM A')))]),_c('span',{staticClass:"ft font-weight-medium text-sm"},[_vm._v("Event Name: "+_vm._s(event.name))])]),_c('div',{staticClass:"d-flex flex-grow-1 flex-row"},[_c('span',{staticClass:"ft font-weight-bold text-sm"},[_vm._v("Amt: ₵"+_vm._s(amountPaid))])])]),_c('div',{staticClass:"d-flex flex-column align-end"},[_c('img',{staticClass:"rounded-right-side",attrs:{"src":"https://res.cloudinary.com/llolollolo/image/upload/v1709205993/wear_ghana/wearghana2024.jpg","width":"150","height":"100%"}})])])}),0):_c('EmptyPage',{staticClass:"mt-8",attrs:{"image":"https://res.cloudinary.com/llolollolo/image/upload/v1709205962/wear_ghana/empty_placeholder.png","title":"No tickets available to display.","subTitle":"All purchased tickets will show up here."}})],1)],1)],1)])])])])])]):_c('InternetConnection',{scopedSlots:_vm._u([{key:"action",fn:function(){return [_c('v-btn',{staticClass:"animate__animated animate__fadeIn",attrs:{"rounded":"","depressed":"","color":"primary"}},[_c('span',{staticClass:"ft font-weight-medium text-sm text-capitalize"},[_vm._v("Try Again")])])]},proxy:true}])})],1)])])],1),_c('TicketDetailsBottomSheet',{attrs:{"state":_vm.dialog('details'),"details":_vm.ticketDetails},on:{"actions":_vm.close}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }