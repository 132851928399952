






































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadView, loadWidget, truncateContent } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { Getter, namespace } from 'vuex-class';
import moment from 'moment-timezone';
import {
  IAPIKeys,
  IPaginate,
  IEventActivity,
  ISenderID,
  IUser,
  IPeople,
  IBrands,
  IPurchasedTickets,
} from '@/types/types';
import { Watch } from 'vue-property-decorator';
import { timestamp } from 'rxjs/operators';
import EmptyPage from '@/components/pages/EmptyPage.vue';
const authModule = namespace('auth');
const apiKeyModule = namespace('apiKeys');
const smsModule = namespace('sms');
const eventsModule = namespace('events');
const usersModule = namespace('users');

@Component({
  name: 'Tickets',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    EventCalendar: loadView(`pages/events/components/EventCalendar`),
    CreateEventDialog: loadView(`pages/events/dialogs/CreateEventDialog`),
    DeleteDialog: loadWidget('widgets/DeleteDialog'),
    TicketDetailsBottomSheet: loadView(
      'pages/events/dialogs/TicketDetailsBottomSheet'
    ),
  },
  filters: {
    timestamp(value: string, format?: string) {
      return moment(value).format(format ?? 'Do MMMM, YYYY');
    },
    truncateContent,
  },
})
export default class Tickets extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @Getter('getResetFormState') resetFormState!: boolean;
  @apiKeyModule.Getter('getAPIKeys') accessKeys!: IAPIKeys;

  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  @authModule.Getter('getUserDetails') user!: IUser;
  @eventsModule.Getter('getAllEvents') events!: Array<IEventActivity>;
  @eventsModule.Getter('getAllPeople') people!: Array<IPeople>;
  @eventsModule.Getter('getAllBrands') brands!: Array<IBrands>;
  @eventsModule.Getter('getEventDetails') eventDetails!: IEventActivity;
  @eventsModule.Getter('getAllPaginatedEvents')
  paginatedEventList!: Array<IEventActivity>;
  @eventsModule.Getter('getPagination') paginate!: IPaginate & {
    limit: number;
  };
  @usersModule.Getter('getAllFavoriteIDs') favoriteIDs!: string[];
  @usersModule.Getter('getAllPurchasedTickets')
  purchasedTickets!: IPurchasedTickets[];

  placeholder =
    'https://res.cloudinary.com/llolollolo/image/upload/v1709131267/wear_ghana/k5cl77crqi1k1msh5yoa.png';

  options = {
    page: 1,
    limit: 15,
  };
  activeTab = 0;
  tabs: Array<{ text: string; image: string; index: number }> = [
    {
      text: 'My Tickets',
      image: require('@/assets/images/ticket.png'),
      index: 1,
    },
  ];

  chipGroups: string[] = ['UPCOMING', 'PAST'];
  filter: string | null = null;

  date = [];
  menu = false;
  query = '';
  picker = '';
  times: string[] = [];
  timezones: string[] = [];
  currentDate = '';

  incomingDateToCreateEvent: string | null = null;
  eventFromParent: {
    eventId: number | null;
    date: string | null;
  } | null = null;
  currentPaginatedPage = 1;
  ticketDetails: IPurchasedTickets | null = null;

  @Watch('filter')
  onTicketFilterChange(value: string): void {
    if (value) {
      this.$store.dispatch(
        'users/listAllPurchasedTickets',
        `?filters=${value}`
      );
      return;
    }
    this.$store.dispatch('users/listAllPurchasedTickets');
  }

  openTicketDetails(payload: IPurchasedTickets): void {
    this.open('details');
    console.log(payload);
    this.ticketDetails = payload;
  }

  created(): void {
    // TODO
    this.$store.dispatch('users/listAllPurchasedTickets');
  }
}
